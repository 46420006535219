<script setup>
import { VuePDF, usePDF } from '@tato30/vue-pdf'




const props = defineProps({
  url: {
    type: String,
    required: true
  },
})

const { pdf, pages } = usePDF('/' + props.url)

</script>

<template>
  <div v-for="page in pages" :key="page">
      <VuePDF class="mx-auto w-full border-2 border-gray-400" :pdf="pdf" :page="page" />
  </div>
</template>
